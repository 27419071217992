import { useConfirmationDialog } from './dialog/confirm/ConfirmationDialog';
import { executeEntityLink, getEntityLinkName, hasLink } from 'utils/apiUtils';
import { PopoverMenu } from 'modules/picasso-ui/menu/PopoverMenu';

const whenToggleInListRowMenuProps = {
    anchorOrigin: { 
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: { 
        vertical: 'bottom',
        horizontal: 'right',
    },
}

/**
 * Action menu that you can populate with entries
 *
 * @param {*} items
 *          {
 *              actionsOften: [{title: '', handleClick: () => {}],
 *              actionsRare: [{title: '', handleClick: () => {}],
 *          }
 */
export default function ActionMenu({ items, renderToggle, toggleProps, toggleIcon, menuProps, PopoverProps, anchor }) {

  const actions = items.actionsOften ? [...items.actionsOften] : [];
  if (items.actionsRare && items.actionsRare.length > 0) {
    if (items.actionsOften?.length > 0) {
      actions.push({
        type: 'divider'
      });
    }
    items.actionsRare.forEach(a=>actions.push(a))
  }

  if (anchor === 'listRow') {
    if (!menuProps) {
      menuProps = whenToggleInListRowMenuProps
    } else {
      menuProps = {
        ...whenToggleInListRowMenuProps,
        ...menuProps
      }
    }
  }


  return (
    <PopoverMenu actions={actions} renderToggle={renderToggle} toggleProps={toggleProps} menuProps={menuProps} PopoverProps={PopoverProps} stopPropagation toggleIcon={toggleIcon} />
  )
}

export const useEntityLinkAction = (entity, linkRel, options) => {

  const { getConfirmation } = useConfirmationDialog();

  const buildMenuItem = () => {

    if (!hasLink(entity, linkRel)) {
      return null;
    }

    return {
      title: options?.name || getEntityLinkName(entity, linkRel),
      handleClick: async () => {

        let confirmed;
        if (options.confirmation) {
          confirmed = await getConfirmation(options.confirmation);
        } else {
          confirmed = true;
        }
    
        if (confirmed) {
          if (options.then) {
            executeEntityLink(entity, linkRel).then(options.then);
          } else {
            executeEntityLink(entity, linkRel);
          }
        }
       
      },
    };
  }

  return buildMenuItem();
}
