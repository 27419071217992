import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { FunctionComponent, MouseEvent, ReactNode, useState } from "react";
import ImageViewer from "modules/picasso-modules/image-viewer";
import { SavedAsset } from "./AssetTypes";
import { css } from "@emotion/react";
import Image from "next/image";
import ActionMenu from "components/ActionMenu";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CopyLinkButton } from "modules/picasso-ui/specials/CopyLinkButton";

const stylesDef = {
    imageBox: css({
        border: '1px solid #dcdce7',
        '&:hover .assetActions': {
            display: 'flex',
            gap: '8px',
        },
    }),
    imageBoxInner: {
        maxHeight: '80px',
        maxWidth: '100px',
        overflow: 'hidden',
        height: '100%',
    },
    imageBoxInnerClickable: {
        cursor: 'pointer',
    },
    imageActionsOld: css({
        position: 'absolute',
        top: '-13px',
        right: '-13px',
    }),
    imageActionsBtns: css({
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        display: 'none',
    }),
}

export interface AssetsViewProps {
    assets?: SavedAsset[]
    onImageRemove?: (assetId: string) => void
    /**
     * If true, user can click on the asset and see it in large viewer
     */
    viewerAllowed?: boolean
    styles?: AssetsViewStyles
    imageWidth?: number
    imageHeight?: number
    /**
     * Image will be minified / preloaded. Also specifiy imageWidth and imageHeight when using.
     */
    useNextJsImage?: boolean
    actions?: Action[]
}

export interface AssetsViewStyles {
    imageBox?: any
}

export interface Action {
    icon?: ReactNode
    title: string
    handleClick: (e: MouseEvent<HTMLElement>, asset: SavedAsset) => void
    /**
     * If true, this action is also shown as a floating button on the image.
     */
    direct?: boolean
    type?: 'copyurl' | string
}

export const AssetsView: FunctionComponent<AssetsViewProps> = ({assets, onImageRemove, viewerAllowed, styles, imageWidth, imageHeight, useNextJsImage, actions, ...props}) => { //assets: array of url, can be data urls

    const [viewerOpen, setViewerOpen] = useState(false)
    const [viewerIndex, setViewerIndex] = useState(null)

    const handleViewerClose = () => {
        setViewerOpen(false)
    }

    const handleImageClick = (index, e) => {
        e.stopPropagation()
        setViewerIndex(index)
        setViewerOpen(true)
    }

    const boxSize = imageWidth || imageHeight ? {
        width: imageWidth,
        height: imageHeight,
    } : null
    

    return (
        <>
            <Box display="flex" flexWrap="wrap" gap="16px" alignItems="center" {...props}>
                {assets.map((image, index) => (
                <Box key={image.assetId} sx={[stylesDef.imageBox, styles?.imageBox, boxSize]} position="relative">
                    
                    <Box sx={[stylesDef.imageBoxInner, imageWidth && imageHeight ? {maxHeight: imageHeight+'px', maxWidth: imageWidth+'px' } :null, viewerAllowed && stylesDef.imageBoxInnerClickable]} onClickCapture={viewerAllowed ? (e)=>handleImageClick(index, e) : null}>
                        {useNextJsImage 
                            ? <Image src={image.url} alt="" height={imageHeight} width={imageWidth} objectFit="cover" layout="fixed" loading="lazy" />
                            : <img src={image.url} alt="" width="100%" css={{objectFit: 'cover', minHeight: '100%', display: 'block'}} loading="lazy" />
                        }
                    </Box>

                    {onImageRemove && (
                        <div css={stylesDef.imageActionsOld}>
                            <IconButton onClick={() => onImageRemove(image.assetId)} size="small"><HighlightOffIcon/></IconButton>
                        </div>
                    )}

                    {actions && 
                        <div css={stylesDef.imageActionsBtns} className="assetActions">
                             {actions?.filter(a=>a.direct===true).map((a, idx)=>a.type==='copyurl'
                                    ? <CopyLinkButton 
                                        key={`copyurl-${idx}`}
                                        //@ts-ignore
                                        copyContent={image.url}
                                        showTooltip={true}
                                        tooltipTitle="Copy URL"
                                        showCheckOnSuccess={false}
                                        showRipple
                                        showIcon
                                        hideText
                                        buttonProps={{
                                            color: 'greyDark',
                                            variant: 'outlined',
                                            size: 'small',
                                            sx: {
                                                maxWidth: '30px',
                                                minWidth: 'min-content',
                                                width: '30px',
                                                height: '28.95px',
                                            }
                                        }}
                                    />
                                    :<Tooltip key={a.title} title={a.title}><Button onClick={(e)=>a.handleClick(e, image)} size="small" variant="outlined" color="greyDark" sx={{padding: 0, width: '25px', minWidth: 0}}>{a.icon}</Button></Tooltip>

                                )}
                            <ActionMenu 
                                items={{
                                    actionsOften: actions.map(a=>({...a, handleClick: (e)=>a.handleClick(e, image)}))
                                }}
                                renderToggle={({onClick})=><Button size="small" variant="outlined" color="greyDark" onClick={onClick} sx={{backgroundColor: '#fff !important', width: '30px', height: '30px', minWidth: '0px !important', padding: 0}}><MoreVertIcon /></Button>}
                            />
                           {/*  {actions.map(a=><Button onClick={()=>a.onClick(image)} size="small" variant="outlined" color="greyDark"><HighlightOffIcon/></Button>)} */}
                        </div>
                    }

                    {image.title && <Typography color="text.secondaty">{image.title}</Typography>}

                </Box>
                ))}
            </Box>

            {viewerAllowed && viewerOpen && (
                <ImageViewer
                    src={ assets.map(i=>i.url) }
                    currentIndex={ viewerIndex }
                    closeOnClickOutside={ true }
                    onClose={ handleViewerClose }
                    disableScroll
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: 2000,
                      }}
                />
            )}
        </>
    )

}