import { Button, CircularProgress } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { Check } from "@mui/icons-material";
import PropTypes from 'prop-types';
import { forwardRef } from "react";

const styles = () => ({
    button: {
      //margin: theme.spacing(1),
    },
    progress: {
        color: '#fff',
    }
  });

const ProgressButton = forwardRef((props, ref) => {
    
    const { classes, loading, done, progressProps, ...other } = props;

    if (done) {
        return (
            <Button className={classes.button} {...other} disabled ref={ref}>
                <Check />
            </Button>
        );
    }
    else if (loading) {
        return (
            <Button className={classes.button} {...other} disabled ref={ref}>
                <CircularProgress className={classes.progress} size={25} {...progressProps}/>
            </Button>
        );
    } else {
        return (
            <Button className={classes.button} {...other} ref={ref}/>
        );
    }
})

ProgressButton.defaultProps = {
        loading: false,
        done: false,
};

ProgressButton.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    done: PropTypes.bool,
};

export default withStyles(styles)(ProgressButton);