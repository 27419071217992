import { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isHavingValue, orElse } from 'utils/objectUtils';
import CheckIcon from '@mui/icons-material/Check';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { ElementLink } from '../link/LinkNormal';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ITEM_HEIGHT = 48;

const styleDef = {
  navIcon: {
    fontSize: '18px',
    color: 'text.secondary',
    opacity: '0.7',
  },
  openBlankStyle: {
    marginLeft: '8px',
    fontSize: '14px',
  },
  listItemIcon: theme=>({
    color: theme.palette.text.primary,
    minWidth: '32px !important',
  }),
}

const useStyles = makeStyles((theme) => ({
    selectedSecondaryAction: {
      height: '26px',
    },
    selectedIcon: {
      color: '#526483',
      height: '26px', //should be 24px but 26px looks more centered
    },
  }));

  let itemStyleSecondary = {
    fontSize: '12px',
    color: 'text.secondary',
    '& svg': {
      color: 'text.secondary'
    },
  }

export const PopoverMenu = ({ actions, actionGroups, renderToggle, closeOnClick, paperWidth, paperMinHeight, paperMaxHeight, PopoverProps, menuProps, onClose, PaperProps, bottomBar, greyedOut, disabled, toggleProps, stopPropagation, toggleIcon}) => {

  const [childrenOpen, setChildrenOpen] = useState()

  actions = actions?.filter(a=>a!==null) || []

  actionGroups = actionGroups || [actions]

  closeOnClick = orElse(closeOnClick, true)



  toggleIcon = toggleIcon || 'horiz'

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (e) => {
    setAnchorEl(null);
    if (onClose) {
      onClose()
    }
    setChildrenOpen(null)
    e.stopPropagation()
  }

  const menuItemTitleCss = (theme)=>({
    color: greyedOut&&!disabled?'#939ba8':null, //!disabled reason: if disabled, its already grey
    fontSize: greyedOut?'12px':null,
  })

  const renderMenuItems = (actions, menuItemCss) => actions.map((option, index) => {

    const { type, title, icon, endIcon, isSelected, handleClick, content, disabled: disabledOption, disabledTooltip } = option

    const disabledEffective = disabledOption || disabled

    if (option.children && !endIcon) {
      endIcon = <ArrowRightIcon sx={styleDef.navIcon} />
    }

    if (type === 'divider') {
      return (
        <Box py={0.5} key={index} >
          <Divider></Divider>
        </Box>
      )
    }

    if (type === 'groupTitle') {
      return (
        <Box px={1.5} py={0.5} key={index} color="text.secondary">
          <Typography sx={{fontWeight: '600', fontSize: '12px'}}>{title}</Typography>
        </Box>
      )
    }

    if (option.children) {
      handleClick = () => {
        setChildrenOpen(option.children)
      }
    }

    const handleClickInner = (e) => {
      handleClick(e);
      if (option.children) {
        return;
      }
      if (closeOnClick) {
        handleClose(e);
      }
    }

    const wrapTooltip = (children) => {
      if (disabledEffective && disabledTooltip) {
        return <Tooltip title={disabledTooltip} placement="right"><div>{children}</div></Tooltip>
      } else {
        return children
      }
    }

    const wrapLink = (key, children) => {
      return option.href 
        ? <ElementLink key={key} href={option.href} target={option.target}>{children}</ElementLink>
        : children
    }

    if (!endIcon && option.target === '_blank') {
      endIcon = <OpenInNewIcon sx={styleDef.openBlankStyle} />
    }

    return wrapTooltip(wrapLink(index, 
      <MenuItem
        disabled={disabledEffective}
        key={index}
        sx={[theme=>({
          color: theme.palette.text.primary,
          fontSize: '1em',
          fontWeight: '400',
          pointerEvents: handleClick ? null:'none'
        }),
        menuItemCss
        ]}
        onClick={disabledEffective !== true ? (handleClick?handleClickInner:null) : null}>
          {content ? 
            content()
            :
            <>
              {icon && <ListItemIcon sx={styleDef.listItemIcon}>{icon}</ListItemIcon>}
              <Typography sx={menuItemTitleCss}>{title}</Typography>
              {isSelected === true && (
                <ListItemSecondaryAction className={classes.selectedSecondaryAction}>
                  <CheckIcon className={classes.selectedIcon} />
                </ListItemSecondaryAction>
              )}
              {endIcon && <ListItemIcon sx={styleDef.listItemIcon}>{endIcon}</ListItemIcon>}
          </>
          }
      </MenuItem>
    ))
  })

  const paperMinWidth = '20ch'

  paperMinHeight = paperMinHeight || undefined

  if (paperMaxHeight != null) {
    paperMaxHeight = paperMaxHeight || ITEM_HEIGHT * 4.5
  }
  
  if (paperMaxHeight && paperMinHeight && (paperMinHeight > paperMaxHeight)) {
    paperMaxHeight = paperMinHeight
  }


  const toggle = renderToggle ? renderToggle({
      onClick: handleClick
  }) 
  : <IconButton
    aria-label="more"
    aria-controls="long-menu"
    aria-haspopup="true"
    onClick={handleClick}
    onMouseDown={stopPropagation ? (e)=>{e.stopPropagation();e.preventDefault()}:null}
    onMouseUp={stopPropagation ? (e)=>{e.stopPropagation();e.preventDefault()}:null}
    {...toggleProps}
    >
      {toggleIcon === 'horiz' ? <MoreHorizIcon /> : <MoreVertIcon />}
    </IconButton>

  PaperProps = PaperProps || {}

  const actionGroupCss = (theme)=>({
    flex: 1,
    '&+&': {
      marginBottom: theme.spacing(3),
    },
  })

  const hasGroups = actionGroups.flat().some(a=>a.type==='groupTitle')
  const multi = actionGroups.length > 1

  const menuListCss = ()=>({
    //overflowX: 'auto', 
    maxHeight: isHavingValue(bottomBar) ? (multi? '150px':ITEM_HEIGHT * 4.5) : null,
    flexGrow: 1,
  })

  const boxCss = (theme)=>({
    padding: hasGroups ? theme.spacing(2) : null,
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    overflowY: 'auto',
    //paddingTop:  multi ? theme.spacing(1):null,
  })

  const menuItemCss = (theme)=>({
    paddingLeft: hasGroups ? theme.spacing(1.5):null,
    paddingRight: hasGroups ? theme.spacing(1.5):null,
  })


  const renderActionGroups = (actionGroups) => {

    return <Box display="flex" sx={boxCss} flexGrow={1}>
      {actionGroups.map((actions, idx)=>{
        return (
          <Box key={idx} sx={actionGroupCss}>
            {renderMenuItems(actions.filter(i=>i.type === 'groupTitle'))}
            <MenuList 
              sx={menuListCss}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onMouseDown={(e) => {
                e.stopPropagation()
              }}
              >
              {renderMenuItems(actions.filter(i=>i.type !== 'groupTitle'), menuItemCss)}
            </MenuList>
          </Box>
          )
      })}
    </Box>

  }

  const actionGroupsOpened = childrenOpen ? [childrenOpen] : actionGroups

  return (
    <>
      {toggle}

      {open && (
        <>
          <Popover
            {...menuProps} //old
            {...PopoverProps}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            onClick={(e)=>e.stopPropagation()}
            onMouseDown={(e)=>e.stopPropagation()}
            PaperProps={{
              ...PaperProps,
              className: classes.paper,
              sx: [()=>({
                minWidth: paperMinWidth,
                width: paperWidth,
                maxHeight: paperMaxHeight,
                minHeight: paperMinHeight,
                display: 'flex',
                flexDirection: 'column',
                ...PaperProps.style,
              }),PaperProps.sx]
            }}
            > 
              {childrenOpen && <MenuItem onClick={()=>setChildrenOpen(null)} sx={itemStyleSecondary}><ArrowLeftIcon sx={styleDef.navIcon} />Back</MenuItem>}
              {renderActionGroups(actionGroupsOpened)} 
              {bottomBar &&
                <Box>
                  {bottomBar}
                </Box> 
              }
          </Popover>
        </>
      )}
    </>
  );
}